import React from "react";

import "../css/Contact.css";

function Contact() {
    return (
      <form>
        <label>
          name: <input type={"text"}></input>
        </label>
        <br /><br />
        <label>
          email: <input type={"text"}></input>
        </label>
        <br /><br />
        <label>
          subject: <input type={"text"}></input>
        </label>
        <br /><br />
        <div className="message-area">
          <label>
            message: <textarea rows={10} cols={50} />
          </label>
        </div>
        <br />
        <input type="submit" value="Submit" />
      </form>
    );
}

export default Contact;