import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './js/App';
import About from "./js/About.js";
import Contact from "./js/Contact.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

const mainRouter = (
  <div className="main-div">
    <Router>
    <Link exact to="/">eczenah</Link>
    <div>
      <ul className="nav-bar">
        <li className="nav-item">
          <NavLink exact to="/">
            <span className="nav-item-select">home</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/about">
            <span className="nav-item-select">about</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/contact-me">
            <span className="nav-item-select">contact me</span>
          </NavLink>
        </li>
      </ul>

      <Switch>
        <Route exact path="/" component={App}/>
        <Route path="/about" component={About} />
        <Route path="/contact-me" component={Contact} />
      </Switch>
    </div>
    </Router>
  </div>   
)

ReactDOM.render(
  <React.StrictMode>
    {mainRouter}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
