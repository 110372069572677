import React from "react";

import "../css/About.css";

function About() {
    return (
      <div>
        <p>
        This is site purely made as an aid to use while shopping for skincare products and will not and will never replace any professional advice! If you have any true medical concerns for any serious condition, please see a licensed dermatologist!
        </p>
      </div>
    );
}

export default About;